import { BillingPeriodUnit, CurrencyCode } from "src/components/Account/types"
import {
  hasIndoorClimateSupport,
  isCigaretteDevice,
  isEurekaDevice,
  isMonitoringDevice,
} from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import {
  getDeviceOutageState,
  getOfflineDeviceOverrides,
} from "src/data/homes/logic/partialOutage"
import {
  IHome,
  IStatefulHome,
  IStatefulHomeWithDevices,
  MonitoringType,
} from "src/data/homes/types/homeTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction } from "src/i18n/useTranslate"
import { formatPrice } from "src/utils/formatPrice"

export function getNbrPages(limit: number, total: number) {
  return Math.ceil(total / limit)
}

export function getOffsetsFromPages(limit: number, nbrPages: number): number[] {
  const offsets = []
  for (let i = 0; i < nbrPages; i++) {
    offsets.push(i * limit)
  }
  return offsets
}

export function generateOffsets(
  limit: number,
  total: number,
  startPage: number = 0
): number[] {
  const nbrPages = getNbrPages(limit, total)
  const offsets = []
  for (let i = startPage; i < nbrPages; i++) {
    offsets.push(i * limit)
  }
  return offsets
}

export function monitoringAvailable({
  type,
  home,
  devices,
}: {
  type: MonitoringType
  home: THomeMonitoringAvailability
  devices: TDevice[]
}): boolean {
  switch (type) {
    case MonitoringType.SOUND: {
      return devices.length > 0 && devices.some((d) => isMonitoringDevice(d))
    }
    case MonitoringType.MOTION: {
      return devices.some((d) => !d.placed_outdoors && isMonitoringDevice(d))
    }
    case MonitoringType.OCCUPANCY: {
      return devices.some((d) => !d.placed_outdoors && isMonitoringDevice(d))
    }
    case MonitoringType.TEMPERATURE: {
      return devices.some(isMonitoringDevice)
    }
    case MonitoringType.HUMIDITY: {
      return devices.some(isMonitoringDevice)
    }
    case MonitoringType.MOLD: {
      return devices.some((d) => !d.placed_outdoors)
    }
    case MonitoringType.FIRECO: {
      return (
        (!!home.carbon_monoxide_alarm?.available ||
          !!home.fire_alarm?.available) &&
        devices.some(isEurekaDevice)
      )
    }
    case MonitoringType.CIGARETTE: {
      return (
        !!home.smoking_detection?.available &&
        devices.some((d) => !d.placed_outdoors && isCigaretteDevice(d))
      )
    }

    case MonitoringType.INDOOR_CLIMATE: {
      const hasSupportedDevices =
        devices.filter(hasIndoorClimateSupport).length > 0

      return hasSupportedDevices && !!home.indoor_climate_monitoring
    }

    default: {
      return false
    }
  }
}

interface IHomeMonitoringCardVisibility {
  showNoiseMonitoringCard: boolean
  showCrowdDetectionCard: boolean
  showSmokingDetectionCard: boolean
  showSecurityAlarmCard: boolean
  showCOCard: boolean
  showIndoorClimateMonitoringCard: boolean
}

export type THomeMonitoringAvailability = Pick<
  IHome,
  | "smoking_detection"
  | "fire_alarm"
  | "carbon_monoxide_alarm"
  | "indoor_climate_monitoring"
>
export function getHomeMonitoringCardVisibility({
  home,
  devices,
}: {
  home: THomeMonitoringAvailability
  devices: TDevice[]
}): IHomeMonitoringCardVisibility {
  const deviceOutageState = getDeviceOutageState(devices)
  const {
    hideNoiseMonitoringCard,
    hideCrowdDetectionCard,
    hideSecurityAlarmCard,
    hideSmokingDetectionCard,
  } = getOfflineDeviceOverrides(deviceOutageState)

  const showNoiseMonitoringCard =
    monitoringAvailable({ type: MonitoringType.SOUND, home, devices }) &&
    !hideNoiseMonitoringCard

  const showCrowdDetectionCard =
    monitoringAvailable({ type: MonitoringType.OCCUPANCY, home, devices }) &&
    !hideCrowdDetectionCard

  const showSmokingDetectionCard =
    monitoringAvailable({
      type: MonitoringType.CIGARETTE,
      home,
      devices,
    }) && !hideSmokingDetectionCard

  const showSecurityAlarmCard =
    monitoringAvailable({ type: MonitoringType.MOTION, home, devices }) &&
    !hideSecurityAlarmCard

  const showCOCard = !!(
    home.fire_alarm?.available && home.carbon_monoxide_alarm?.available
  )

  const showIndoorClimateMonitoringCard = monitoringAvailable({
    type: MonitoringType.INDOOR_CLIMATE,
    home,
    devices,
  })

  return {
    showNoiseMonitoringCard,
    showCrowdDetectionCard,
    showSmokingDetectionCard,
    showSecurityAlarmCard,
    showCOCard,
    showIndoorClimateMonitoringCard,
  }
}

// TODO: WEB-569 Move to a hook that fetches both devices and homes and stiches
// together instead.
export function getStatefulHomesWithDevices(
  homes: IStatefulHome[],
  devices: TDevice[]
): IStatefulHomeWithDevices[] {
  return homes
    .map((h) => {
      return {
        ...h,
        devices: devices.filter((d) => d.home === h.home_id),
      }
    })
    .filter((h) => h.devices.length > 0)
}

type TBillingPeriod = `${BillingPeriodUnit}`
type TCurrency = `${CurrencyCode}`

export function getFormattedHomePriceForBillingPeriod({
  price,
  isPriceInCents,
  currencyCode,
  billingPeriod,
  t,
}: {
  price: number
  isPriceInCents: boolean
  currencyCode: TCurrency
  billingPeriod: TBillingPeriod
  t: TTranslateFunction
}) {
  const _price = isPriceInCents ? price / 100 : price

  const rawMonthlyPrice = billingPeriod === "month" ? _price : _price / 12
  const rawAnnualPrice = billingPeriod === "year" ? _price : _price * 12

  const monthlyPrice = formatPrice(rawMonthlyPrice, currencyCode)
  const annualPrice = formatPrice(rawAnnualPrice, currencyCode)

  const formattedMonthlyPrice = `${formatPrice(
    rawMonthlyPrice,
    currencyCode
  )}/${t(langKeys.month).toLowerCase()}`

  const formattedAnnualPrice = `${formatPrice(
    rawAnnualPrice,
    currencyCode
  )}/${t(langKeys.year).toLowerCase()}`

  return {
    formattedMonthlyPrice,
    formattedAnnualPrice,
    monthlyPrice,
    annualPrice,
  }
}
